import { useLocation } from "@remix-run/react";

export default function useResumablePath() {
  const { pathname, search } = useLocation();

  // Redirect to home if the current path is an access route
  if (pathname.includes("/access")) {
    return "/";
  }

  // Correctly encode URI components and handle deep linking
  const encodePathComponent = (component) => encodeURIComponent(component).replace(/%24/g, "$");

  // Convert '&' to '$' and prepare each query parameter individually for deep linking
  const query = new URLSearchParams(search);
  const encodedSearch = Array.from(query.entries())
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodePathComponent(value)}`)
    .join("&")
    .replace(/&/g, "$");

  // Create a resumable path that maintains deep linking compatibility
  const resumablePath = `${encodeURIComponent(pathname)}${encodedSearch ? "?$" + encodedSearch : ""}`;

  return resumablePath;
}
